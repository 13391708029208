import axios from "axios";
import { get, post, put, patch, delete_request } from "../utils/HttpRequest";

const { REACT_APP_API_URL, REACT_APP_ECOMMERCE_URL } = process.env;
const token = localStorage.getItem("token");

/* =================================================== Authentication ========================================================== */
export const authLogin = async (params) => {
  try {
    const response = await axios.post(`${REACT_APP_API_URL}/login/admin`, params);
    const token = response.data.data.token.access_token;
    localStorage.setItem("token", token);

    return response;
  } catch (error) {
    console.log("Login failed: ", error);
    throw error;
  }
};

export const resetPasword = async (params) => {
  const token = params.token_reset_password;
  const url = `${REACT_APP_API_URL}/reset-password?token_reset_password=${token}`;
  return await post(url, params);
};

export const forgotPassword = async (data) => {
  return await post(`${REACT_APP_API_URL}/forget-password`, data);
};

export const changePassword = async (data) => {
  const token = localStorage.getItem("token");
  return await put(`${REACT_APP_ECOMMERCE_URL}/user/update`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const logOut = () => {
  localStorage.removeItem("token");
};

/* ============================================ Dashboard ========================================================== */
export const getMainDataDashboard = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/dashboard`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMainDataDashboardDaily = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/dashboard?range_time=day`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMainDataDashboardMonthly = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/dashboard?range_time=month`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getMainDataDashboardYearly = async (params) => {
  const { start, end } = params;
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/dashboard?range_time=year&start_year=${start}&end_year=${end}`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getSummaryDashboard = async (id) => {
  return await get(`${REACT_APP_API_URL}/dashboard-chart/summary?distributor_id=${id}`);
};
export const getSellingChart = async (params) => {
  const { id, type, start_date, end_date } = params;
  return await get(
    `${REACT_APP_API_URL}/dashboard-chart/order?distributor_id=${id}&type=${type}&start_date=${start_date}&end_date=${end_date}`,
  );
};
export const getOutletChart = async (params) => {
  const { id, type, start_date, end_date } = params;
  return await get(
    `${REACT_APP_API_URL}/dashboard-chart/outlet?distributor_id=${id}&type=${type}&start_date=${start_date}&end_date=${end_date}`,
  );
};
export const getLeaderboard = async (params) => {
  const { id, type } = params;
  return await get(
    `${REACT_APP_API_URL}/dashboard-chart/leaderboard?distributor_id=${id}&type=${type}`,
  );
};
export const getLeaderboardDownload = async (params) => {
  const {
    id,
    type,
    type_order,
    type_outlet,
    start_date_order,
    end_date_order,
    start_date_outlet,
    end_date_outlet,
  } = params;
  return await get(
    `${REACT_APP_API_URL}/dashboard-chart/download?distributor_id=${id}&type=${type}&type_order=${type_order}&type_outlet=${type_outlet}&start_date_order=${start_date_order}&end_date_order=${end_date_order}&start_date_outlet=${start_date_outlet}&end_date_outlet=${end_date_outlet}`,
  );
};

/* =================================================== Admin ========================================================== */
// Management Admin Users
export const getAllAdmin = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/admin`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const AddData = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/admin/register`;
  return await post(url, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export function editData(id, params) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/admin/${id}`,
    data: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function DeleteData(id) {
  return axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/admin/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

/* =================================================== Management Customer ========================================================== */
export const getListCustomers = async (params) => {
  const { offset, limit, order, keyword } = params;
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/customers/all?offset=${offset}&limit=${limit}&order=${order}&keyword=${keyword}`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCustomerDetail = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/customers/${params}`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
/* Add Ons Management */
export const getListAddOns = async (params) => {
  const { offset, limit, keyword } = params;
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${process.env.REACT_APP_API_URL}/catering?search=${keyword}&page=${offset}&page_size=${limit}&status=NONE`;
  return await post(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOneAddOns = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/catering/${params}`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postAddOns = async (data) => {
  const token = localStorage.getItem("token");
  try {
    if (!token) {
      throw new Error("No token found");
    }
    const url = `${process.env.REACT_APP_API_URL}/catering/create`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await post(url, data, config);
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const putAddOns = async (params, data) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${process.env.REACT_APP_API_URL}/catering/${params}`;
  return await put(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteAddOns = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${process.env.REACT_APP_API_URL}/catering/${params}`;
  return await delete_request(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
/* =================================================== Office Managenemt ========================================================== */
export const getListOffice = async (params) => {
  const { page, page_size, search, category } = params;
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/offices/all?search=${search}&page=${page}&page_size=${page_size}&category=${category}&status=NONE`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOfficeCategory = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/offices/category`;
  const response = await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status) {
    const categoryData = response.data.data.map((res) => ({
      categoryID: res.id,
      categoryName: res.category_name,
    }));
    return categoryData;
  } else {
    throw new Error(response.message || "Failed to fetch office category data");
  }
};

export const getRegionIdAndName = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/regions`;
  const response = await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status) {
    const regionData = response.data.data.map((res) => ({
      regionID: res.id,
      regionName: res.name,
    }));
    return regionData;
  } else {
    throw new Error(response.message || "Failed to fetch region data");
  }
};

export const getOfficeIDAndName = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/offices/all`;
  const response = await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status) {
    const officeData = response.data.data.data.map((office) => ({
      id: office.id,
      name: office.name,
    }));
    return officeData;
  } else {
    throw new Error(response.message || "Failed to fetch office data");
  }
};

export const getListOfficeDetail = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/office/${params}`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postOffice = async (params, navigate) => {
  const token = localStorage.getItem("token");

  try {
    if (!token) {
      throw new Error("No token found");
    }

    const url = `${process.env.REACT_APP_API_URL}/office/create`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(url, params, config);
    return response;
  } catch (error) {
    console.log(error);

    if (error.response && error.response.status === 401) {
      navigate("/admin/login", { replace: true });
    }

    return error.response;
  }
};

export const deleteOffice = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/office/${params}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await delete_request(url, config);
};

export const putOffice = async (params, data) => {
  const token = localStorage.getItem("token");
  try {
    if (!token) {
      throw new Error("No token found");
    }
    const url = `${REACT_APP_API_URL}/office/${params}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    return await put(url, data, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateOfficeCatalogue = async (officeCatalogueId, formData) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}/office/catalogue/${officeCatalogueId}`, {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to update office catalogue");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating office catalogue:", error);
    throw error;
  }
};

export const addOfficeCatalogue = async (officeId, formData) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}/office/catalogue/${officeId}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to add office catalogue");
    }
    return await response.json();
  } catch (error) {
    console.error("Error adding office catalogue:", error);
    throw error;
  }
};

export const deleteOfficeCatalogue = async (officeCatalogueId) => {
  try {
    const response = await fetch(`${REACT_APP_API_URL}/office/catalogue/${officeCatalogueId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to delete office catalogue");
    }
    return response;
  } catch (error) {
    console.error("Error deleting office catalogue:", error);
    throw error;
  }
};

/* =================================================== Booking Managenemt ========================================================== */

export const getListBooking = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/booking/all`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBookingDetail = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/booking-cust/${params}`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteBooking = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/booking/${params}`;
  return await delete_request(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createBooking = async (bodyData) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/booking/create`;
  return await post(url, bodyData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const cancelBooking = async (params, data) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  return await put(`${REACT_APP_API_URL}/booking/cancel/${params}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

/* =================================================== Manage Order ========================================================== */

export const getListOrders = async (params) => {
  const {
    offset,
    limit,
    order,
    order_status,
    no_order,
    outlet_name,
    distributor,
    start_date,
    end_date,
    distributor_id,
  } = params;
  return await get(
    `${REACT_APP_API_URL}/orders?order_status=${order_status}&offset=${offset}&limit=${limit}&order=${order}&no_order=${no_order}&outlet_name=${outlet_name}&distributor=${distributor}&start_date=${start_date}&end_date=${end_date}&distributor_id=${distributor_id}`,
  );
};

export const getOrdersDetail = async (params) => {
  return await get(`${REACT_APP_API_URL}/orders/${params}`);
};

export const processOrders = async (data) => {
  return await post(`${REACT_APP_API_URL}/orders/process`, data);
};

export const rejectOrders = async (data) => {
  return await post(`${REACT_APP_API_URL}/orders/reject`, data);
};

export const requestDeliveryConfirmation = async (data) => {
  return await post(`${REACT_APP_API_URL}/orders/delivery-confirmation`, data);
};

export const changeStatusOrders = async (params, data) => {
  return await put(`${REACT_APP_API_URL}/products/principles/${params}`, data);
};

export const getListRatingOrders = async (params) => {
  const { offset, limit, order, order_number, outlet_name, pbf_id } = params;
  return await get(
    `${REACT_APP_API_URL}/orders/rating?offset=${offset}&limit=${limit}&order=${order}&order_number=${order_number}&outlet_name=${outlet_name}&pbf_id=${pbf_id}`,
  );
};

export const getTrackingOrders = async (params) => {
  return await get(`${REACT_APP_API_URL}/orders/tracking/${params}`);
};

export const getRatingOrdersDetail = async (params) => {
  return await get(`${REACT_APP_API_URL}/orders/rating/${params}`);
};

export const getOrderCreditLimit = async (params) => {
  const { outlet_id, distributor_id } = params;
  return await get(
    `${REACT_APP_API_URL}/orders/outlet/credit-limit?outlet_id=${outlet_id}&distributor_id=${distributor_id}`,
  );
};

export const getListRetur = async (params) => {
  const {
    offset,
    limit,
    order,
    no_retur,
    status,
    outlet_name,
    pbf,
    start_date,
    end_date,
    distributor_id,
  } = params;
  return await get(
    `${REACT_APP_API_URL}/retur?status=${status}&offset=${offset}&limit=${limit}&order=${order}&no_retur=${no_retur}&outlet_name=${outlet_name}&pbf=${pbf}&start_date=${start_date}&end_date=${end_date}&distributor_id=${distributor_id}`,
  );
};

export const getReturDetail = async (params) => {
  return await get(`${REACT_APP_API_URL}/retur/${params}`);
};
export const changeStatusRetur = async (params, data) => {
  return await put(`${REACT_APP_API_URL}/retur/status/${params}`, data);
};

//S&K Pengembalian

export const getListTermsConditionsRetur = async (params) => {
  const { offset, limit, order, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/retur/terms-conditions?offset=${offset}&limit=${limit}&order=${order}&keyword=${keyword}`,
  );
};

export const getDetailTermsConditionsRetur = async (params) => {
  return await get(`${REACT_APP_API_URL}/retur/terms-conditions/${params}`);
};
export const postTermsConditionsRetur = async (data) => {
  return await post(`${REACT_APP_API_URL}/retur/terms-conditions`, data);
};
export const putTermsConditionsRetur = async (params, data) => {
  return await put(`${REACT_APP_API_URL}/retur/terms-conditions/${params}`, data);
};

// Manage Outlet Verification
export const getOutletList = async (params) => {
  const { offset, limit, order, status, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/outlet-verifications?status=${status}&offset=${offset}&limit=${limit}&order=${order}&keyword=${keyword}`,
  );
};

export const getOutletDetails = async (params) => {
  return await get(`${REACT_APP_API_URL}/outlet-verifications/${params}`);
};

export const approveOutlet = async (params, data) => {
  return await patch(`${REACT_APP_API_URL}/outlet-verifications/approved/${params}`, data);
};

export const rejectOutlet = async (params, data) => {
  return await patch(`${REACT_APP_API_URL}/outlet-verifications/rejected/${params}`, data);
};

/* =================================================== Banner & Articles ========================================================== */

export const getBannerList = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/banner/all`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postInformation = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  return await post(`${REACT_APP_API_URL}/information/create`, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putInformation = async (params, data) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  return await put(`${REACT_APP_API_URL}/information/${params}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getInformationList = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/information/all`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteInformation = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/information/${params}`;
  return await delete_request(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBannerDetail = async (params) => {
  return await get(`${REACT_APP_API_URL}/content/banners/${params}`);
};

export const postBanner = async (params) => {
  return await post(`${REACT_APP_API_URL}/content/banners`, params);
};

export const putBanner = async (params, data) => {
  return await put(`${REACT_APP_API_URL}/content/banners/${params}`, data);
};

export const deleteBanner = async (params) => {
  return await delete_request(`${REACT_APP_API_URL}/content/banners/${params}`);
};

// Manage Articles
export const getArticleList = async (params) => {
  const { offset, limit, order, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/content/articles?offset=${offset}&limit=${limit}&order=${order}&keyword=${keyword}`,
  );
};

export const getArticleDetail = async (params) => {
  return await get(`${REACT_APP_API_URL}/content/articles/${params}`);
};

export const postArticle = async (params) => {
  return await post(`${REACT_APP_API_URL}/content/articles`, params);
};

export const putArticle = async (params, data) => {
  return await put(`${REACT_APP_API_URL}/content/articles/${params}`, data);
};

export const deleteArticle = async (params) => {
  return await delete_request(`${REACT_APP_API_URL}/content/articles/${params}`);
};

/* =================================================== CMS Management ========================================================== */

// Contact Us
export const getContactUs = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/information-contact/all`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postContactUs = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/information-contact/create`;
  return await post(url, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putContactsUs = async (id, params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/information-contact/${id}`;
  return await put(url, params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteContactsUs = async (id) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/information-contact/${id}`;
  return await delete_request(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// FAQ
export const getFAQ = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/faq/all`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFAQDetail = async (params) => {
  return await get(`${REACT_APP_API_URL}/faq/${params}`);
};

export function postFAQ(params) {
  const isToken = localStorage.getItem("token");
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/faq/create`,
    data: params,
    headers: {
      Authorization: `Bearer ${isToken}`,
    },
  });
}

export function putFAQ(id, params) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/faq/${id}`,
    data: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function deleteFAQ(id) {
  return axios({
    method: "delete",
    url: `${REACT_APP_API_URL}/faq/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// Mitra Kami
export const getMitra = async () => {
  return await get(`${REACT_APP_API_URL}/information/mitra`);
};

export const postMitra = async (data) => {
  return await post(`${REACT_APP_API_URL}/information/mitra`, data);
};

export const putMitra = async (data) => {
  return await put(`${REACT_APP_API_URL}/information/mitra`, data);
};

// Syarat dan Ketentuan
export const getSyaratKetentuan = async () => {
  return await get(`${REACT_APP_ECOMMERCE_URL}/information/syarat_ketentuan`);
};

export const postSyaratKetentuan = async (data) => {
  return await post(`${REACT_APP_API_URL}/information/syarat_ketentuan`, data);
};

export const putSyaratKetentuan = async (data) => {
  return await put(`${REACT_APP_API_URL}/information/syarat_ketentuan`, data);
};

export const deleteSyaratKetentuan = async (id) => {
  return await delete_request(`${REACT_APP_API_URL}/information/syarat_ketentuan/${id}`);
};

// API Upload General
export const uploadGeneral = async (data) => {
  return await post(`${REACT_APP_ECOMMERCE_URL}/uploads/image`, data);
};

// API Delete General
export const deleteGeneral = async (params) => {
  return await delete_request(`${REACT_APP_API_URL}/uploads?file=${params}`);
};

// List Region
export const getListProvince = async (params) => {
  return await get(`${REACT_APP_ECOMMERCE_URL}/region/provinces?name${params}`);
};

export const getListRegency = async (params) => {
  return await get(`${REACT_APP_ECOMMERCE_URL}/region/regencies?province_id=${params}`);
};

export const getListDistrict = async (params) => {
  return await get(`${REACT_APP_ECOMMERCE_URL}/region/districts?regency_id=${params}`);
};

export const getListVillage = async (params) => {
  return await get(`${REACT_APP_ECOMMERCE_URL}/region/villages?district_id=${params}`);
};

// Manage Discount
// Cash Back
export const getDiscountCashback = async (params) => {
  const { offset, limit, order, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/discount/cashback?offset=${offset}&limit=${limit}&order=${order}&keyword=${keyword}`,
  );
};

export const getDiscountCashbackDetail = async (params) => {
  return await get(`${REACT_APP_API_URL}/discount/cashback/${params}`);
};

export const postDiscountCashback = async (data) => {
  return await post(`${REACT_APP_API_URL}/discount/cashback`, data);
};

export const putDiscountCashback = async (params, data) => {
  return await put(`${REACT_APP_API_URL}/discount/cashback/${params}`, data);
};

export const deleteDiscountCashback = async (params) => {
  return await delete_request(`${REACT_APP_API_URL}/discount/cashback/${params}`);
};

export const postFlashSale = async (data) => {
  return await post(`${REACT_APP_API_URL}/discount/flashsale`, data);
};

export const putFlashSale = async (params, data) => {
  return await put(`${REACT_APP_API_URL}/discount/flashsale/${params}`, data);
};

export const deleteFlashSale = async (params) => {
  return await delete_request(`${REACT_APP_API_URL}/discount/flashsale/${params}`);
};

//HNA2

export const postHNA2 = async (data) => {
  return await post(`${REACT_APP_API_URL}/discount/hna`, data);
};
export const putHNA2 = async (params, data) => {
  return await put(`${REACT_APP_API_URL}/discount/hna/${params}`, data);
};

export const getHNA2 = async (params) => {
  const { order, offset, limit, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/discount/hna?order=${order}&offset=${offset}&limit=${limit}&keyword=${keyword}`,
  );
};
export const getDetailHNA2 = async (data) => {
  return await get(`${REACT_APP_API_URL}/discount/hna/${data}`);
};

export const deleteHNA2 = async (params) => {
  return await delete_request(`${REACT_APP_API_URL}/discount/hna/${params}`);
};

// Get Product By PBF
export const getProductByPBF = async (params) => {
  return await get(`${REACT_APP_API_URL}/products/management/pbf/${params}`);
};

export const getProductByMultiplePBF = async (params) => {
  const { pbf_id, order, offset, limit, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/products/management/multiple-pbf?pbf_id=${pbf_id}&order=${order}&offset=${offset}&limit=${limit}&keyword=${keyword}`,
  );
};

/* =================================================== PROMO MANAGEMENT ========================================================== */

export const getDiscount = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/discounts`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteDiscount = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/discount/${params}`;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await delete_request(url, config);
};

export const putDiscount = async (params, data) => {
  const token = localStorage.getItem("token");
  try {
    if (!token) {
      throw new Error("No token found");
    }
    const url = `${REACT_APP_API_URL}/discount/${params}`;
    const config = {
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    return await put(url, data, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getDiscountDetail = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/discount/${params}`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postDiscount = async (data) => {
  const token = localStorage.getItem("token");
  try {
    if (!token) {
      throw new Error("No token found");
    }
    const url = `${REACT_APP_API_URL}/discount`;
    const config = {
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.post(url, data, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFlashSale = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/flashsales`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFlashsaleDetail = async (params) => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }
  const url = `${REACT_APP_API_URL}/flashsale/${params}`;
  return await get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Import Product Data (Excel)
export const importProductData = async (data) => {
  return await post(`${REACT_APP_API_URL}/import/product`, data);
};

// Download Data
export const downloadListProduct = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    throw new Error("No token found");
  }

  return await post(`${REACT_APP_API_URL}/office/report`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const downloadListRetur = async (params) => {
  const { order, status } = params;
  return await get(`${REACT_APP_API_URL}/download/list-retur?order=${order}&status=${status}`);
};

export const downloadTemplateImport = async () => {
  return await get(`${REACT_APP_API_URL}/download/products/template`);
};

export const downloadListAdminUsers = async (params) => {
  const { order, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/download/list-admin-users?order=${order}&keyword=${keyword}`,
  );
};

export const downloadListCustomer = async (params) => {
  const { order, keyword } = params;
  return await get(`${REACT_APP_API_URL}/download/list-customer?order=${order}&keyword=${keyword}`);
};

export const downloadListDistributor = async (params) => {
  const { order, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/download/list-distributor?order=${order}&keyword=${keyword}`,
  );
};

export const downloadListAdminDistributor = async (params) => {
  const { order } = params;
  return await get(`${REACT_APP_API_URL}/download/list-admin-distributor?order=${order}&keyword=`);
};

export const downloadListPrinciples = async (params) => {
  const { order, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/download/list-principles?order=${order}&keyword=${keyword}`,
  );
};

export const downloadListOrder = async (params) => {
  const { order_status = "", order, outlet_id } = params;
  const queryParams = outlet_id ? `&outlet_id=${outlet_id}` : "";
  return await get(
    `${REACT_APP_API_URL}/download/list-order?order_status=${order_status}&order=${order}${queryParams}`,
  );
};
export const downloadListComplaint = async (params) => {
  const { status, order } = params;
  return await get(`${REACT_APP_API_URL}/download/list-complain?status=${status}&order=${order}`);
};

export const downloadListOrderRating = async (params) => {
  const { offset, limit, order, order_number, outlet_name, pbf } = params;
  return await get(
    `${REACT_APP_API_URL}/download/list-order-rating?offset=${offset}&limit=${limit}&order=${order}&order_number=${order_number}&outlet_name=${outlet_name}&pbf=${pbf}`,
  );
};

export const downloadListOrderInvoice = async (params) => {
  const { offset, limit, order, outlet_id, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/download/list-order-invoice?outlet_id=${outlet_id}&keyword=${keyword}&order=${order}&offset=${offset}&limit=${limit}`,
  );
};

export const downloadReportList = async (params) => {
  const { offset, order, limit, keyword, start_date, end_date } = params;
  return await get(
    `${REACT_APP_API_URL}/download/list-order-report?offset=${offset}&limit=${limit}&order=${order}&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}`,
  );
};

export const downloadInvoice = async (id) => {
  return await get(`${REACT_APP_API_URL}/download/invoice/${id}`);
};

export const getNotificationUser = async (params) => {
  const { offset, limit, order } = params;
  return await get(
    `${REACT_APP_API_URL}/notification?offset=${offset}&limit=${limit}&order=${order}`,
  );
};

export const downloadListMembership = async (params) => {
  const { offset, order, limit, keyword } = params;
  return await get(
    `${REACT_APP_API_URL}/marketing/outlet-membership/download?offset=${offset}&limit=${limit}&order=${order}&keyword=${keyword}`,
  );
};
export const downloadListCourier = async (params) => {
  const { order } = params;
  return await get(`${REACT_APP_API_URL}/download/list-courier?order=${order}`);
};

// Manage Report
export const getReportList = async (params) => {
  const { offset, order, limit, keyword, start_date, end_date, pbf_id } = params;
  return await get(
    `${REACT_APP_API_URL}/order-report?offset=${offset}&limit=${limit}&order=${order}&keyword=${keyword}&start_date=${start_date}&end_date=${end_date}&pbf_id=${pbf_id}`,
  );
};

export const getReportDetail = async (params) => {
  return await get(`${REACT_APP_API_URL}/order-report/${params}`);
};

// Manage Complain
export const getComplaintList = async (params) => {
  const { offset, order, limit, keyword, status } = params;
  return await get(
    `${REACT_APP_API_URL}/complain?offset=${offset}&limit=${limit}&order=${order}&q=${keyword}&status=${status}`,
  );
};

export const getComplaintDetail = async (id) => {
  return await get(`${REACT_APP_API_URL}/complain/${id}`);
};

export const postComplaint = async (data) => {
  return await post(`${REACT_APP_API_URL}/complain/respon`, data);
};

export const putComplaint = async (id) => {
  const payload = {
    status: "Selesai",
  };
  return await put(`${REACT_APP_API_URL}/complain/done/${id}`, payload);
};

// Master Distributtor Additional
export const getListBank = async () => {
  return await get(`${REACT_APP_API_URL}/master-banks`);
};

/* =================================================== Management Courier ========================================================== */

export const getListCourier = async (data) => {
  const { q, distributor_id, order } = data;
  return await get(
    `${REACT_APP_API_URL}/courier?order=${order}&q=${q}&distributor_id=${distributor_id}`,
  );
};
export const postCourier = async (data) => {
  return await post(`${REACT_APP_API_URL}/courier`, data);
};
export const putCourier = async (id, data) => {
  return await put(`${REACT_APP_API_URL}/courier/${id}`, data);
};
export const deleteCourier = async (id) => {
  return await delete_request(`${REACT_APP_API_URL}/courier/${id}`);
};

/* =================================================== Payment List ========================================================== */

export const getPaymentList = async () => {
  return await get(`${REACT_APP_API_URL}/payment/lists`);
};
