import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvent } from "react-leaflet";
import L from "leaflet";
import iconUrl from "../../assets/images/location-pin.png";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";

// Define a default set of coordinates to prevent undefined issues
const DEFAULT_COORDINATES = { lat: -6.2, lng: 106.816666 }; // Example: Jakarta

const customIcon = new L.Icon({
  iconUrl: iconUrl,
  iconSize: [40, 40],
});

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const GeoSearch = ({ setCoordinates }) => {
  const map = useMap();

  useEffect(() => {
    const provider = new OpenStreetMapProvider(); // Use OpenStreetMapProvider

    const searchControl = new GeoSearchControl({
      provider,
      style: "bar",
      marker: {
        icon: customIcon,
        draggable: false,
      },
      showMarker: true,
      retainZoomLevel: false,
      autoClose: true,
      searchLabel: "Enter address",
      keepResult: true,
      maxMarkers: 1,
    });

    map.addControl(searchControl);

    map.on("geosearch/showlocation", (result) => {
      if (result?.location?.lat && result?.location?.lng) {
        const { lat, lng } = result.location;
        setCoordinates({ lat, lng });
      } else {
        console.error("Invalid result from search");
      }
    });

    return () => {
      map.removeControl(searchControl);
    };
  }, [map, setCoordinates]);

  return null;
};

const MapClickHandler = ({ setPosition, setCoordinates }) => {
  useMapEvent("click", (e) => {
    const { lat, lng } = e.latlng;
    setPosition([lat, lng]);
    setCoordinates({ lat, lng });
  });
  return null;
};

const CenterMapOnMarker = ({ position }) => {
  const map = useMap();
  useEffect(() => {
    if (position && position[0] && position[1]) {
      map.setView(position, map.getZoom(), { animate: true });
    }
  }, [position, map]);

  return null;
};

const Map = ({ initialCoordinates = DEFAULT_COORDINATES, setCoordinates }) => {
  const [position, setPosition] = useState([initialCoordinates.lat, initialCoordinates.lng]);

  useEffect(() => {
    if (initialCoordinates && initialCoordinates.lat && initialCoordinates.lng) {
      setPosition([initialCoordinates.lat, initialCoordinates.lng]);
    }
  }, [initialCoordinates]);

  return (
    <MapContainer center={position} zoom={15} scrollWheelZoom={true} style={mapContainerStyle}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <MapClickHandler setPosition={setPosition} setCoordinates={setCoordinates} />

      <CenterMapOnMarker position={position} />

      <Marker position={position} icon={customIcon}>
        <Popup>
          Lat: {position[0]}, Lng: {position[1]}
        </Popup>
      </Marker>

      <GeoSearch setCoordinates={setCoordinates} />
    </MapContainer>
  );
};

export default Map;
